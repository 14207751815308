.Highlight {
  font-weight: 700;
  text-transform: uppercase;
  /* letter-spacing: 0.025em; */
  line-height: 22px;
}

.Question {
  margin-top: 40px;
  margin-bottom: 60px;
}

.Question h2 {
  /* font-size: 20px; */
}

.FormContainer {
  transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.Disabled {
  opacity: 0.3;
  pointer-events: none;
}

.Option {
  display: block;
  margin: 0 auto;
  /* width: 50%; */
  max-width: 700px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-align: center;
  outline: 0;
  transition: color 150ms ease-out;
}

.Option:hover,
.Option__selected {
  color: #c35a58;
  cursor: pointer;
}

.OptionSeparator {
  composes: Highlight;

  text-transform: none;
  margin: 40px auto;
}

.Checkbox {
  display: block;
  padding: 0;
  margin: 25px auto;
  width: 64px;
  height: 64px;
  appearance: none;
  background: transparent;
  border: 8px solid #c35a58;
  transform: rotateZ(45deg);
  outline: 0;
  border-radius: 0;
}

.Checkbox:checked:before,
.Checkbox:checked:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  border-radius: 8px;
  height: 70px;
  background: #5b5658;
}

.Checkbox:checked:before {
  transform: translate(-50%, -50%);
}

.Checkbox:checked:after {
  transform: translate(-50%, -50%) rotateZ(90deg);
}

.NextStep,
.Infos {
  display: inline-block;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 12px;
  padding-right: 12px;
  border: 2px dashed #5b5658;
  color: #5b5658;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
  /* letter-spacing: 0.025em; */
}

.Sponsors {
  display: flex;
  width: 100%;
  height: 120px;
  padding: 30px 20px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
  align-items: center;
  justify-content: space-between;
}

.Sponsors a {
  display: block;
  padding: 0 10px;
  flex: 1;
}

.Sponsors img {
  max-width: 100%;
  height: auto;
}

.Form {
  text-align: left;
}

.Form input[type='text'] {
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  background: #ddd;
  border: 0;
  margin-bottom: 20px;
  /* font-family: Open Sans Condensed, sans-serif; */
  /* font-size: 20px; */
  /* line-height: 30px; */
}

.FormCheckbox {
  position: relative;
  display: block;
  padding-left: 40px;
  margin-bottom: 20px;
  line-height: 30px;
}

.FormCheckbox input {
  position: absolute;
  top: 2px;
  left: 0;

  background: #ddd;
  appearance: none;
  width: 20px;
  height: 20px;
}

.FormCheckbox input:checked:before,
.FormCheckbox input:checked:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  border-radius: 2px;
  height: 20px;
  background: #c35a58;
}

.FormCheckbox input:checked:before {
  transform: translate(-50%, -50%) rotateZ(45deg);
}

.FormCheckbox input:checked:after {
  transform: translate(-50%, -50%) rotateZ(-45deg);
}

.FormHint {
  text-align: right;
  font-size: 15px;
  margin-top: -10px;
  margin-bottom: 40px;
}

.Submit {
  margin-top: 20px;
  margin-bottom: 20px;

  /* font-family: Open Sans Condensed, sans-serif; */
  /* font-size: 20px; */
  font-weight: bold;
  text-transform: uppercase;

  background: #ddd;
  color: #c35a58;
  padding-left: 20px;
  padding-right: 20px;
  border: 0;

  background-repeat: no-repeat;
  background-position: 90% 49%;
  background-size: auto 15px;
  cursor: pointer;
}

.Submit:disabled {
  opacity: 0.6;
  pointer-events: none;
}

.Submit:hover {
  background-color: #f3e5e4;
}

.Conditions {
  color: #5b5658;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 700px) {
  .Conditions {
    padding-left: 0;
    padding-right: 0;
  }
}

.Conditions a {
  color: #5b5658;
}

.Legal {
  font-size: 12px;
}

.Logos {
  margin-bottom: 1em;
}

.Logos a {
  display: flex;
  height: 150px;
}

.Logos img {
  margin: auto;
  display: block;
  max-width: 100%;
  height: auto;
  transform: scale(0.5);
  transform-origin: center 0;
}

@media (max-width: 700px) {
  .Logos img {
    width: 100%;
  }
}

.Page h2 {
  margin-top: 2em;
  /* font-size: 16px; */
}

.Hotels img {
  width: 100%;
}

.Hotels a {
  color: #c35a58;
  text-transform: uppercase;
}

.Hotels > div {
  margin-bottom: 1em;
}

.HotelsDescription {
  display: flex;
  padding-left: 20px;
  flex-direction: column;
}

.HotelsDescription p {
  margin-top: 0;
}

.Back {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;

  overflow: hidden;
  display: block;
  width: 51px;
  height: 69px;
  /* background: no-repeat url(./images/close.png) 0 0; */
  text-indent: -999em;
}
